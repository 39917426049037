import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugify from "slugify"
import { Col, Card } from "react-bootstrap"
import styled from "styled-components"

const FeaturedBlogList = ({ blogs = [] }) => {
  return (
    <>
      {blogs.map(blog => {
        const { id, title, image, description } = blog
        const slug = slugify(title, { lower: true })
        const pathToImage = getImage(image)

        return (
          <>
            <Col sm={12} md={6} className="pb-3 d-flex align-items-stretch">
              <BoxStyle>
                <StyledLink key={"ft" + id} to={`/${slug}`}>
                  <Card className="index-content h-100">
                    <GatsbyImage image={pathToImage} alt={title} />
                    <Card.Body>
                      <Card.Title>{blog.title}</Card.Title>
                      <Card.Text>{blog.description.description}</Card.Text>
                      <footer className="card-footer">
                        Published on: {blog.updatedAt}
                      </footer>
                    </Card.Body>
                  </Card>
                </StyledLink>
              </BoxStyle>
            </Col>
          </>
        )
      })}
    </>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;

  & :focus,
  & :hover,
  & :visited,
  & :link,
  & :active {
    text-decoration: none;
    background-color: smokewhite;
  }
`

const BoxStyle = styled.div`
  box-shadow: 3px 3px 12px rgb(0 0 0 / 12%);
  background: transparent;
  border-color: #b5cfff;
  border-bottom-style: solid;

  :hover {
    background-color: #17008005;
  }

  .card-footer {
    text-align-last: right;
    font-size: x-small;
    background-color: #17008005;
  }

  .card.index-content {
    min-height: 300;
    padding: 0;

    & :hover {
      text-decoration: none;
      background-color: #d6d6d633;
    }
  }
`

export default FeaturedBlogList
