import React from "react"
import { Link } from "gatsby"
import { Col } from "react-bootstrap"
import { FcNext } from "react-icons/fc"
import { FcPrevious } from "react-icons/fc"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <>
      <Col className="text-center">
        {previousPagePath && (
          <span>
            <Link to={previousPagePath}>
              <FcPrevious
                style={{
                  width: "75px",
                  fontSize: "2.5em",
                  backgroundColor: "lightslategray",
                }}
              />
            </Link>
          </span>
        )}
      </Col>
      <Col className="text-center">
        {nextPagePath && (
          <span>
            <Link to={nextPagePath}>
              <FcNext
                style={{
                  width: "75px",
                  fontSize: "2.5em",
                  backgroundColor: "lightslategray",
                }}
              />
            </Link>
          </span>
        )}
      </Col>
    </>
  )
}

export default Pager
