import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugify from "slugify"
import { Col, Card } from "react-bootstrap"
import styled from "styled-components"

const BlogList = ({ blogs = [] }) => {
  return (
    <>
      {blogs.map(blog => {
        const { id, title, image, description } = blog
        const slug = slugify(title, { lower: true })
        const pathToImage = getImage(image)
        // console.log(blogs)

        return (
          <>
            <Col
              sm={12}
              md={6}
              xl={4}
              className="pb-3 d-flex align-items-stretch"
            >
              <BoxStyle>
                <StyledLink key={id} to={`/${slug}`}>
                  <Card className="index-content h-100">
                    <GatsbyImage image={pathToImage} alt={title} />
                    <Card.Body>
                      <Card.Title>{blog.title}</Card.Title>
                      <Card.Text>{blog.description.description}</Card.Text>
                      <footer className="card-footer">
                        Published on: {blog.updatedAt}
                      </footer>
                    </Card.Body>
                  </Card>
                </StyledLink>
              </BoxStyle>
            </Col>
          </>
        )
      })}
    </>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;

  & :focus,
  & :hover,
  & :visited,
  & :link,
  & :active {
    text-decoration: none;
    background-color: smokewhite;
  }
`
const BoxStyle = styled.div`
  & div > div .card-footer {
    text-align-last: right;
    font-size: x-small;
    background-color: #17008005;
  }

  .card.index-content {
    min-height: 300;
    padding: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 3px rgb(0 0 0 / 5%);

    & :hover {
      text-decoration: none;
      background-color: #d6d6d633;
    }
  }
`

export default BlogList
