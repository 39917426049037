import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import FeaturedBlogList from "./FeaturedBlogList"

const AllFeaturedBlogs = () => {
  const data = useStaticQuery(query)
  const blogs = data.allContentfulBlog.nodes

  return (
    <>
      <FeaturedBlogList blogs={blogs} />
    </>
  )
}

const query = graphql`
  {
    allContentfulBlog(
      sort: { fields: publishDate, order: DESC }
      filter: { node_locale: { eq: "en-US" }, featured: { eq: true } }
      limit: 2
    ) {
      nodes {
        id
        title
        description {
          description
        }
        updatedAt(formatString: "yyyy/MM/DD")
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
      }
    }
  }
`

export default AllFeaturedBlogs
