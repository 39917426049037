import React from "react"
import { graphql } from "gatsby"
import Pager from "../components/Pager/Pager"

import BlogList from "../Blog/BlogList"
import AllFeaturedBlogs from "../Blog/AllFeaturedBlogs"

import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import DivSeperator from "../components/HomePage/DivSeperator"
import NewsLetter from "../components/Email/NewsLetter"

const Blog = ({ data, pageContext }) => {
  // console.log(data)
  // console.log("page: " + pageContext.pageNumber)
  const blogs = data.allContentfulBlog.nodes
  return (
    <>
      <Layout>
        <Seo title="Amazon FBA Seller Blog" description="Amazon FBA Blog" />

        <Container fluid className="px-0">
          <Row className="g-0">
            <Col>
              <NewsLetter />
            </Col>
          </Row>
          <Row className="g-0">
            <Col>
              <Container className="p-5">
                {pageContext.pageNumber == 0 && (
                  <>
                    <Row className="mx-auto">
                      <h3>Featured Posts</h3>
                      <Row className="mx-auto">
                        <AllFeaturedBlogs />
                      </Row>
                    </Row>
                    <DivSeperator />
                  </>
                )}
                <Row className="mx-auto" style={{ placeContent: "center" }}>
                  <BlogList blogs={blogs} />
                </Row>
                <DivSeperator />
                <Row className="mx-auto" style={{ placeContent: "center" }}>
                  <Pager pageContext={pageContext} />
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Blog

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlog(
      sort: { fields: publishDate, order: DESC }
      filter: { node_locale: { eq: "en-US" } }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        title
        featured
        updatedAt(formatString: "yyyy/MM/DD")
        description {
          description
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
      }
    }
  }
`
